.wrap {
  // position: fixed;
  // bottom: 40px;
  // right: 40px;
  border-radius: 4px;
  // width: calc(100% - 16px);
  max-width: 400px;
  transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  animation: fadeIn 0.3s forwards;
  border: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  margin-top: 8px;
  z-index: 999999;
  pointer-events: none;

  .inner {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    gap: 8px;

    p {
      font-size: 24px;
    }

    .close_container {
      width: 20px;
      height: 20px;
      pointer-events: all;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: 450px) {
    max-width: 450px;
  }
}
.fade_out {
  transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  animation: fadeOut 0.3s forwards;
  animation-delay: 5.3s;
}

.success {
  background: #80B2B0;
  p {
    color: #006A65 !important
  }
}
.error {
  background: #FFE0E0;  
  p {
    color: black !important
  }
}
.info {
  background: #E0F3FF
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    // transform: translate3d(0, 10px, 0)
  }
  100% {  
    opacity: 1;
    // transform: translate3d(0, 0, 0)
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    // transform: translate3d(0, 0, 0)
  }
  100% {
    opacity: 0;
    // transform: translate3d(0, 10px, 0)
  }
}