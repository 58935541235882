.wrap {
  position: fixed;
  bottom: 0;
  right: 0;
  min-width: 343px;
  width: fit-content;
  pointer-events: none;
  z-index: 99999;
  // height: 100%;
  display: flex;
  max-height: 99999px;

  .inner {
    margin-top: auto;
    position: relative;
    padding: 24px;
    width: 100%;
    // overflow: scroll;
  }

  @media screen and (min-width: 960px) {
    min-width: 400px;
  }
}