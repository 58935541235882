// @import url('./fonts/friz.otf');

@font-face {
  font-family: 'Friz Quadrata Std';
  src: url('./fonts/friz.otf');
}


body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #006A65
}

h1, h2, h3, h4, h5, h6, p, a, button, label, input {
  color: #D5E5E4;
  font-size: 16px;
  margin: 0;
  // font-family: 'Friz'
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Friz Quadrata Std';
}
p, a, label, button, input, span {
  font-family: 'Questrial', sans-serif;
}
.error_message {
  color: rgb(255, 133, 133);
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 18px;
}

p, label, button {
  font-size: 16px;
}
input {
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 22px;
  }
  
  h2 {
    font-size: 20px;
  }
  
  p, label, button {
    font-size: 16px;
  }
  input {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  h1 {
    font-size: 28px;
  }
  
  h2 {
    font-size: 24px;
  }
  
  p, label, button {
    font-size: 16px;
  }
  input {
    font-size: 16px;
  }
}

@media screen and (min-width: 1280px) {
  h1 {
    font-size: 36px;
  }
  
  h2 {
    font-size: 32px;
  }
  
  p, label, button {
    font-size: 16px;
  }
  input {
    font-size: 16px
  }
}


button, input {
  border: none;
  padding: 12px;    
  border-radius: 4px;
}

button {
  background: black;
  color: #D5E5E4;

  &:hover {
    cursor: pointer;
  }
}
input {
  color: black;
}

.App {
  align-items: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // padding: 0 16px;
}

.content {
  padding: 80px 0;
  // max-width: 1440px;
  margin: auto;

  .logo_container {
    padding-bottom: 80px;
  }

  .form_section {
    margin: 40px auto;
    
    form {
      margin: 40px auto;
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 750px;
      width: 100%;
      text-align: left;
      justify-content: center;

      label {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      input { 
        border: none;
        box-shadow: 0 0 5px inset black; 
        background: #D5E5E4;
      }
    }
  }
}

.built_by {
  padding: 24px 0;
}

.connection_status {
  position: fixed;
  top: 16px;
  right: 16px;

  svg {
    width: 24px;
    height: 24px;
    color: #D5E5E4;

    @media screen and (min-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }
}