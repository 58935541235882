.video_loading {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 1);
  z-index: 9;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    svg {
      width: 40px !important;
      height: 40px !important;
      animation-name: rotate; 
      animation-duration: 1.0s; 
      animation-iteration-count: infinite;
      animation-timing-function: linear;  
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to { 
    transform: rotate(360deg);
  }
} 